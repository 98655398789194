<template>
  <section id="top">
    <div>
      <v-img
        src="/img/hero_w.png"
        max-width="1000"
        contain
        class="hero-img"
      ></v-img>
    </div>
    <div class="btn-div">
      <v-btn
        color="secondary"
        class="font-weight-bold"
        :to="{ name: 'Around' }"
      >
        アプリを起動
      </v-btn>
    </div>
    <v-divider class="my-8"></v-divider>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  watch: {},
  methods: {},
};
</script>


<style scoped>
.hero-img {
  margin: auto;
  margin-top: 20px;
}

section {
  position: relative;
}

.btn-div {
  text-align: center;
  padding: 16px;
}

.marker {
  background: linear-gradient(transparent 80%, #1c5a9f 80%);
  padding-bottom: 8px;
}
</style>
