<template>
  <v-footer color="primary" padless>
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="link in links"
        :key="link.text"
        color="white"
        text
        :to="link.to"
        :href="link.href"
        :target="link.target"
        rounded
        class="my-2"
      >
        {{ link.text }}
      </v-btn>

      <v-col class="primary py-0 text-center white--text" cols="12">
        <v-img
          :src="require('@/assets/KIWA-LOGO-white.png')"
          max-width="220"
          height="80"
          contain
          style="margin: auto"
        ></v-img>
      </v-col>
      <v-col class="primary py-2 text-center white--text" cols="12">
        © {{ new Date().getFullYear() }} —
        <strong>KIWA ART AND DESIGN inc.</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    links: [
      {
        text: "利用規約",
        to: { name: "TermsOfUse" },
      },
      {
        text: "プライバシーポリシー",
        to: { name: "PrivacyPolicy" },
      },
      {
        text: "運営会社",
        href: "https://kaad.jp/",
        target: "_blank",
      },
    ],
  }),
};
</script>
