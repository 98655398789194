<template>
  <section id="concept">
    <v-container fluid id="concepts" class="mt-2">
      <v-row align="center" justify="center">
        <h1 class="display-1 font-weight-bold mb-6">
          <span class="marker px-4">Concepts</span>
        </h1>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" xs="12" md="6" xl="4">
          <v-carousel light cycle hide-delimiter-background height="550">
            <v-carousel-item v-for="(concept, i) in concepts" :key="i">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  light
                  class="card pb-6 px-10 mx-10 my-10"
                  min-height="500"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-img
                    :src="concept.img"
                    max-width="250px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"
                  ></v-img>
                  <h1 class="font-weight-regular">{{ concept.title }}</h1>
                  <h4 class="font-weight-regular subtitle-1">
                    {{ concept.text }}
                  </h4>
                </v-card>
              </v-hover>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      concepts: [
        {
          img: require("@/assets/concept_pic/00.jpg"),
          title: "EVの価値をあらゆる人に届ける",
          text: "EVライフスタイルの構築に十分な情報をエンドユーザーに届くようなサービスを提供いたします。",
        },
        {
          img: require("@/assets/concept_pic/04.jpg"),
          title: "目的地に到着するまでの充電プランがわかる",
          text: "EV充電器を探すために必要な自車両のデータやルート条件設定などのパラメータがあり、より本質的なプラン設計が可能になります。",
        },
        {
          img: require("@/assets/concept_pic/05.jpg"),
          title: "直感的に EV 運用ができるようになる",
          text: "バッテリー残量表示がグラデーションで表現されるなど、より直感的に操作ができるような UI 設計を実現します。",
        },
      ],
    };
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.pause();
      }
    },
  },
  methods: {},
};
</script>


<style scoped>
section {
  position: relative;
}

.marker {
  background: linear-gradient(transparent 80%, #1c5a9f 80%);
  padding-bottom: 8px;
}
</style>
