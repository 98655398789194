<template>
  <section id="whatsNew">
    <v-container fluid id="concepts" class="mt-2">
      <v-row align="center" justify="center">
        <h1 class="display-1 font-weight-bold mb-6">
          <span class="marker px-4">What’s New</span>
        </h1>
      </v-row>
      <v-row
        v-for="update in updates.slice(0, 3)"
        :key="update.id"
        align="center"
        justify="center"
      >
        <v-col cols="12" xs="12" md="6" xl="4">
          <v-chip
            class="ma-2"
            :color="update.type === 'update' ? 'primary' : 'accent'"
            :text-color="update.type === 'update' ? 'white' : 'black'"
          >
            {{ update.type }}
          </v-chip>
          <span>{{ update.date }}</span>
          <router-link :to="{ name: 'Update', hash: `#update-${update.id}` }">
            <p class="px-4">{{ update.description }}</p>
          </router-link>
          <v-divider />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
const updates = require("@/assets/updates.json");

export default {
  data() {
    return {
      updates,
    };
  },
  methods: {},
};
</script>

<style scoped>
section {
  position: relative;
}

.marker {
  background: linear-gradient(transparent 80%, #1c5a9f 80%);
  padding-bottom: 8px;
}
</style>
