<template>
  <v-app>
    <v-main>
      <v-app-bar dark color="primary" app flat>
        <v-img
          v-if="$vuetify.breakpoint.mdAndUp"
          :src="require('@/assets/icon.png')"
          max-width="45"
          contain
        ></v-img>
        <v-toolbar-title class="font-weight-bold mx-2">
          EV充電器マップ
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          class="font-weight-bold"
          :to="{ name: 'Around' }"
        >
          アプリを起動
        </v-btn>
      </v-app-bar>
      <TopSection />
      <UpdateSection />
      <ConceptSection />
      <FeatureSection />
      <Footer />
    </v-main>
    <CookieConsent />
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import CookieConsent from "@/components/CookieConsent.vue";
import TopSection from "@/components/LandingPage/TopSection.vue";
import ConceptSection from "@/components/LandingPage/ConceptSection.vue";
import UpdateSection from "@/components/LandingPage/UpdateSection.vue";
import FeatureSection from "@/components/LandingPage/FeatureSection.vue";
import Footer from "@/components/LandingPage/Footer.vue";

export default {
  name: "Home",
  components: {
    CookieConsent,
    TopSection,
    ConceptSection,
    UpdateSection,
    FeatureSection,
    Footer,
  },
  computed: mapState(["trackingAllowed"]),
  mounted() {
    if (this.trackingAllowed) {
      console.log("tracking start");
      this.$gtag.optIn();
    } else {
      console.log("tracking disabled");
      this.$gtag.optOut();
    }
  },
  data: () => ({
    //
  }),
  metaInfo: {
    title: "ホーム",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "お近くの電気自動車用充電器を検索！ルートを設定した充電計画作成にご活用ください。電気自動車を便利に安心して利用する手助けに！",
      },
    ],
  },
};
</script>