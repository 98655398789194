<template>
  <section id="howto" class="my-4">
    <v-container fluid id="how" class="mt-2">
      <v-row align="center" justify="center">
        <h1 class="display-1 font-weight-bold mb-6">
          <span class="marker px-4">Features</span>
        </h1>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" xs="12" md="6" xl="4">
          <v-carousel light cycle hide-delimiter-background>
            <v-carousel-item v-for="feature in features" :key="feature.title">
              <v-card class="ma-4" light flat>
                <v-responsive :aspect-ratio="4 / 3">
                  <v-container>
                    <v-row align="center" justify="center">
                      <v-img :src="feature.src" max-width="400"></v-img>
                    </v-row>
                    <v-row align="center" justify="center">
                      <h1 class="ma-4">{{ feature.title }}</h1>
                    </v-row>
                    <v-row align="center" justify="center">
                      <h2 class="font-weight-light">
                        {{ feature.text }}
                      </h2>
                    </v-row>
                  </v-container>
                </v-responsive>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      features: [
        {
          src: require("@/assets/feature_pic/around.png"),
          title: "周辺の充電器を検索",
          text: "現在地周辺のEV 充電器を素早く見つけることができます",
        },
        {
          src: require("@/assets/feature_pic/search.png"),
          title: "ルート上の充電器を検索",
          text: "電池残量のシミュレーションを行い充電プランの検討ができます",
        },
        {
          src: require("@/assets/feature_pic/fav.png"),
          title: "お気に入りの充電器を保存",
          text: "よく利用する充電器の情報の素早くアクセスできます",
        },
      ],
    };
  },
  watch: {},
  methods: {},
};
</script>

<style scoped>
section {
  position: relative;
}

.marker {
  background: linear-gradient(transparent 80%, #1c5a9f 80%);
  padding-bottom: 8px;
}
</style>
